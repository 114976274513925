import styled from "@emotion/styled";
import Layout from "components/Layout";
import { useUserAuthContext } from "context/UserAuthContext";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";
import { Button, TextField } from "ui";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  text-align: center;
  min-width: 325px;
  max-width: 400px;
`;

const SubRedirectText = styled.p`
  text-align: center;
  font-size: 14px;
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const { login, user } = useUserAuthContext();
  const { push } = useRouter();

  const submit = () => {
    if (!email) return;
    login(email);
  };

  useEffect(() => {
    if (user?.issuer) push("/user");
  }, [user, push]);

  return (
    <Wrapper>
      <Content>
        <div>
          <h2>Välkommen</h2>
          <p>Logga in för att se ditt sublyfe abbonemang</p>
        </div>
        <TextField
          variant="outlined"
          label="Email"
          type="email"
          value={email}
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          aria-label="logga in"
          variant="contained"
          fullWidth
          onClick={submit}
        >
          Logga in
        </Button>
        <SubRedirectText>
          Är du medarbetare på en salong och har ett befintligt inlog? Logga in{" "}
          <a href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}>här</a>
        </SubRedirectText>
      </Content>
    </Wrapper>
  );
};

Login.getLayout = function getLayout(page: ReactElement) {
  return <Layout headerLinks={false}>{page}</Layout>;
};

export default Login;
